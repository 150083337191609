import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from '../locales/en/translation.json';
import ptBRTranslation from '../locales/pt-BR/translation.json';
import esTranslation from '../locales/es/translation.json';
import frTranslation from '../locales/fr/translation.json';
import hiTranslation from '../locales/hi/translation.json';
import idTranslation from '../locales/id/translation.json';
import ruTranslation from '../locales/ru/translation.json';
import jaTranslation from '../locales/ja/translation.json';
import zhCNTranslation from '../locales/zh-CN/translation.json';
import deTranslation from '../locales/de/translation.json';
import itTranslation from '../locales/it/translation.json';
import arTranslation from '../locales/ar/translation.json';
import bnTranslation from '../locales/bn/translation.json';
import elTranslation from '../locales/el/translation.json';
import koTranslation from '../locales/ko/translation.json';
import swTranslation from '../locales/sw/translation.json';
import ukTranslation from '../locales/uk/translation.json';

const resources = {
  en: {
    translation: enTranslation,
  },
  'pt-BR': {
    translation: ptBRTranslation,
  },
  es: {
    translation: esTranslation,
  },
  fr: {
    translation: frTranslation, 
  },
  hi: {
    translation: hiTranslation,
  },
  id: {
    translation: idTranslation,
  },
  ru: {
    translation: ruTranslation,
  },
  ja: {
    translation: jaTranslation,
  },
  'zh-CN': {
    translation: zhCNTranslation,
  },
  de: {
    translation: deTranslation,
  },
  it: {
    translation: itTranslation,
  },
  ar: {
    translation: arTranslation,
  },
  bn: {
    translation: bnTranslation,
  },
  el: {
    translation: elTranslation,
  },
  ko: {
    translation: koTranslation,
  },
  sw: {
    translation: swTranslation,
  },
  uk: {
    translation: ukTranslation,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: navigator.language || 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;